<template>
  <header :class="['ps-header ps-header--11 ps-header--12', { 'ps-header--sticky': sticky }]">
    <div class="ps-header__middle" style="background: #47c1b0;">
      <div class="container">
        <div class="ps-logo">
          <router-link to="/">
            <img :src="logo" alt="Logo">
            <img class="sticky-logo" :src="logo" alt="Logo">
          </router-link>
        </div>
        <div class="ps-header__right">
          <ul class="ps-header__icons">
            <li class="">
              <a href="#" class="active  ps-header__item open-search"
                 @click.prevent="handleOpenDrawer('search')"><i class="icon-magnifier"></i></a>
            </li>
            <li class="ps-header__user">
              <router-link to="/profile" class="active  ps-header__item">
                <i class="icon-user"></i>
              </router-link>
              <login-modal v-if="user==null"/>
            </li>
            <!--                        <li class="">
                                        <router-link to="/profile/favorites" class="ps-header__item"><i class="fa fa-heart-o"></i>
                                            <span class="badge">{{wishlistItems.length }}</span></router-link>
                                    </li>-->
            <li class="ps-header__cart">
              <a href="/cart" class="active  ps-header__item"><i class="icon-cart-empty"></i>
                <span class="badge">{{ productCartQuantity }}</span>
              </a>
              <CartMini/>
            </li>
          </ul>
          <div class="ps-language-currency" v-if="languages.length>1">
            <a class="ps-dropdown-value" href="#" @click.prevent="handleOpenLanguageDialog()">
              {{ language }}</a>
          </div>
          <div class="ps-header__menu">
            <MenuRenderer :list="mainMenu" :promos="promos" className="menu"/>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

import MenuRenderer from "./MenuRenderer";
import LoginModal from "../modals/LoginModal";
import {mapState} from "vuex";
import CartMini from "../modals/CartMini";
import logo from "../../assets/img/logo.png";
import {getBlocks, getCategories} from "../../repositories/GeneralDataRepository";

export default {
  name: "TheHeader",
  components: {
    CartMini,
    LoginModal, MenuRenderer,
  },
  data() {
    return {
      logo: logo,
      categories: [],
      promos: [],
      product: null,
      sticky: false,
      showNavigation: false
    }
  },
  computed: {
    ...mapState({
      cartItems: state => state.cart.cartItems,
      wishlistItems: state => state.wishlist.items,
      appDrawer: state => state.app.appDrawer,
      language: state => state.app.language,
      languageCode: state => state.app.languageCode,
      languages: state => state.app.languages,
      user: state => state.auth.user
    }),
    mainMenu() {
      return [
        /*{
            "text": "Məhsullar",
            "url": "/products",
            "icon": "fa fa-bars",
            "extraClass": "has-mega-menu",
            "megaContent": [
                {
                    "heading": "Kateqoriyalar",
                    "megaColumn": "col-12 col-sm-5",
                    "megaItems": this.categories,
                },
                {
                    "megaColumn": "col-12 col-sm-5 col-md-7",
                    "promo": true
                }
            ]
        },*/
        {
          "icon": "fa fa-bars",
          "text": "Məhsullar",
          "url": "/products",
          "extraClass": "nav-item"
        },
        {
          "text": "Haqqımızda",
          "url": "/page/about",
          "extraClass": "nav-item"
        },
        {
          "text": "Çatdırılma və ödəniş",
          "url": "/page/delivery",
          "extraClass": "nav-item"
        },
        {
          "text": "Bloq",
          "url": "/blog",
          "extraClass": "nav-item"
        },
        {
          "text": "Əlaqə",
          "url": "/contact",
          "extraClass": "nav-item"
        }
      ]
    },
    productCartQuantity() {
      let quantity = 0;
      this.cartItems.forEach(element => {
        quantity += element.count;
      });
      return quantity;
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  }
  ,
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  ,
  async mounted() {
    await getBlocks(this.languageCode, 'menu', true).then(data => {
      this.promos = data;
    });
    await getCategories(this.languageCode).then(data => {

      const categories = [];

      data.forEach(cat => {
        categories.push({
          text: cat.name,
          url: `/category/${cat.slug}`
        })
      })

      this.categories = categories;
    });
  }
  ,
  methods: {
    handleScroll() {
      let scroll = window.scrollY;
      if (scroll > 200) {
        this.sticky = true;
      } else {
        this.sticky = false;
      }
    }
    ,
    handleOpenDrawer(drawer) {
      this.$store.commit('app/setCurrentDrawerContent', drawer);
      this.$store.commit('app/setAppDrawer', !this.appDrawer);
    }
    ,
    handleOpenMenu() {
      this.showNavigation = !this.showNavigation;
    }
    ,
    handleOpenLanguageDialog() {
      this.$store.commit('app/setDialogLanguage', true);
    }
  },

}
</script>

<style scoped>

</style>
