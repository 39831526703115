import axios from 'axios';

const baseDomain = 'https://api.bouquet.az';
const image = 'https://api.bouquet.az/photo.php?w=200&h=200&zc=1&src=';
const imageWide = 'https://api.bouquet.az/photo.php?w=1000&h=600&zc=1&src=';
const imageLarge = 'https://api.bouquet.az/photo.php?w=1000&h=1000&zc=1&src=';


export const customHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
};

export const baseUrl = `${baseDomain}`;
export const imagePath = `${image}`;
export const imageWidePath = `${imageWide}`;
export const imagePathLarge = `${imageLarge}`;

export default axios.create({
    baseUrl,
    headers: customHeaders
});

export const serializeQuery = query => {
    return Object.keys(query)
        .map(
            key =>
                `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
        )
        .join('&');
};
