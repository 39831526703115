<template>
  <div>


    <section-banner :listData="banners" :round="true" v-if="!isMobile"/>

    <div v-if="isMobile" style="height: 60px;"></div>

    <h2 class="literata-regular text-center mt-5 text-info">Buketlərimiz</h2>

    <FeaturedTags/>

    <featured-product :title="this.settings.home_all_products_title" tag="all" class="mt-0"
                      v-if="settings.home_all_products_visible=='yes'"
                      :count="settings.home_all_products_count"
                      :buttonVisible="settings.home_home_all_products_button=='yes'"

    />

    <featured-product :title="this.settings.home_top_selling_title" tag="top"
                      v-if="settings.home_top_selling_visible=='yes'"
                      :count="settings.home_top_selling_count"
                      :buttonVisible="settings.home_top_selling_more_button=='yes'"

    />

    <featured-product :title="this.settings.home_new_products_title" tag="new"
                      v-if="settings.home_new_products_visible=='yes'"
                      :count="settings.home_new_products_count"
                      :buttonVisible="settings.home_new_products_more_button=='yes'"
    />

    <featured-categories :title="this.settings.home_categories_title"
                         v-if="settings.home_categories_visible=='yes'"
                         :count="settings.home_categories_count"
                         :buttonVisible="settings.home_categories_more_button=='yes'"/>


    <blog-list-view
        v-if="settings.home_blog_visible=='yes'"
        :count="settings.home_blog_count"
        :buttonVisible="settings.home_blog_more_button=='yes'"
    />


  </div>
</template>

<script>
import SectionBanner from "../layout/SectionBanner";
import FeaturedProduct from "../layout/FeaturedProduct";
import {getBlocks} from "../../repositories/GeneralDataRepository";
import {baseUrl} from "../../repositories/Repository";
import roundImg from "../../assets/img/round.png";
import {mapState} from "vuex";
import FeaturedCategories from "@/components/layout/FeaturedCategories.vue";
import BlogListView from "@/components/layout/BlogListView.vue";
import FeaturedTags from "@/components/layout/FeaturedTags.vue";

export default {
  name: "HomePage",
  components: {FeaturedTags, BlogListView, FeaturedCategories, SectionBanner, FeaturedProduct},
  data() {

    return {
      banners: [],
      promotionTop: [],
      isMobile: true
    };

  },
  computed: {
    ...mapState({
      'languageCode': state => state.app.languageCode,
      'settings': state => state.app.settings,
    })
  },
  async mounted() {
    this.$store.dispatch('app/setLoading', true);

    this.isMobile = window.innerWidth < 768;


    await getBlocks(this.languageCode, 'sliders', false).then(data => {
      if (data.length < 1) {
        this.$router.push("/");
      } else {
        const banners = [];

        data.forEach(function (item) {
          banners.push({
            "title": item.title,
            "desc": item.text,
            "shop": "bg-warning",
            "img": baseUrl + item.photo,
            "link": item.link,
            "round": roundImg,
            "bgColor": "#fcfcfe",
            "bannerRound": false,
            "filter": "filter: invert(9%) sepia(19%) saturate(9%) hue-rotate(352deg) brightness(101%) contrast(91%);"
          })
        })

        this.banners = banners;
      }
    })

    await getBlocks(this.languageCode, 'banners', true).then(data => {
      if (data.length < 1) {
        this.$router.push("/");
      } else {
        this.promotionTop = data;
      }
    })

    this.$store.dispatch('app/setLoading', false);

  }
}
</script>

<style scoped>

</style>
