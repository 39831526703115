<template>
  <header :class="['ps-header ps-header--12 ps-header--mobile', {'ps-header--sticky': sticky}]">
    <div class="ps-header__middle"  style="background: #47c1b0;">
      <div class="container">
        <div class="ps-logo">
          <router-link to="/">
            <img :src="logo" alt
            /></router-link>
        </div>
        <div class="ps-header__right">
          <ul class="ps-header__icons">
            <li>
              <a
                  class="ps-header__item open-search"
                  href="#"
                  @click.prevent="handleOpenDrawer('search')"
              ><i class="icon-magnifier"></i
              ></a>
            </li>
            <li>

              <router-link to="/profile" class="ps-header__item open-search">
                <i class="icon-user"></i>
              </router-link>
            </li>
            <li>

              <a
                  class="ps-header__item open-search"
                  href="javascript: void(0);"
                  @click="handleOpenDrawer('menu')"
              ><i class="icon-menu"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import {mapState} from 'vuex';
import logo from "../../assets/img/logo.png";

export default {
  components: {},
  data() {
    return {
      logo: logo,
      sticky: false
    }
  },
  computed: {
    ...mapState({
      appDrawer: state => state.app.appDrawer,
      cartItems: state => state.cart.cartItems,
    }),

    productCartQuantity() {
      let quantity = 0;
      this.cartItems.forEach(element => {
        quantity += element.count;
      });
      return quantity;
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      let scroll = window.scrollY;
      if (scroll > 700) {
        this.sticky = true;
      } else {
        this.sticky = false;
      }
    },
    handleOpenDrawer(drawer) {
      this.$store.commit('app/setCurrentDrawerContent', drawer);
      this.$store.commit('app/setAppDrawer', !this.appDrawer);
    }
  }
};
</script>
