import Repository, {baseUrl} from './Repository';


export async function getTagProducts(languageCode, tag) {
    const endPoint = `${languageCode}/products/tag/${tag}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return formatProducts(response.data.products);
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getCategory(languageCode, slug) {
    const endPoint = `${languageCode}/category/${slug}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data.category;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getTag(languageCode, slug) {
    const endPoint = `${languageCode}/tag/${slug}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data.tag;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getCategoryProducts(languageCode, categoryId) {
    const endPoint = `${languageCode}/category/${categoryId}/products`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return formatProducts(response.data.products);
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getAllProducts(languageCode, query, category, tag, page, size, order) {
    const endPoint = `${languageCode}/search?query=${query}&category=${category}&tag=${tag}&page=${page}&size=${size}&order=${order}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return {'products': formatProducts(response.data.products), total: response.data.total};
            } else {
                return {'products': [], total: 0};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {'products': [], total: 0};
        });
}


export async function getProduct(languageCode, slug) {
    const endPoint = `${languageCode}/product/${slug}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


function formatProducts(products) {

    let final = [];

    products.forEach(function (product) {
        final.push(formatProduct(product, []));
    });

    return final;

}

let tagNames = {
  new: 'Yeni',
  sale: "Endirim",
  season: "Mövsümi güllər",
  campaign: "Aksiya"
};

export function formatProduct(product, gallery) {
    let data = {
        "id": product.id,
        "sku": product.sku,
        "name": product.name,
        "features": product.features,
        "featured": true,
        "percent": product.discount,
        "price": product.discount > 0 ? (product.price / (1 - (product.discount / 100))).toFixed(2) : product.price,
        "sale_price": product.discount > 0 ? product.price : null,
        "on_sale": product.discount > 0,
        "slug": product.slug,
        "is_stock": product.inStock,
        "rating": product.ratingCount > 0 ? (product.ratingTotal / product.ratingCount).toFixed(1) : 0,
        "rating_count": product.ratingCount || 0,
        "thumbnail": {
            "url": product.photo
        },
        "description": product.description,
        "short_description": " ",
        "sizes": [],
        "colors": [],
        "badges": [],
        "images": [{url: product.photo, alt: product.name}],
        "thumbnail_back": {"url": product.photoSecondary === "" ? product.photo : product.photoSecondary},
        "video": product.video,
        "category": {
            name: product.category.name,
            slug: product.category.slug,
            id: product.category.id
        }
    };

    if (product.tag !== '') {
        product.tag.split(',').forEach(function (t) {
            if(tagNames[t]) {
                data.badges.push({value: t, name: tagNames[t]});
            }
        })
    }

    gallery.forEach(function ({photo, name}) {
        data.images.push({
            url: photo,
            alt: name
        })
    })

    return data;
}
