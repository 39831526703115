<template>
  <div class="container">
    <section :class="['ps-section--banner', sectionClass]">
      <div class="ps-section__overlay" v-show="loadingBanner">
        <div class="ps-section__loading"></div>
      </div>
      <div class="ps-swiper">
        <div class="swiper">
          <swiper :slides-per-view="1" navigation :pagination="{ clickable: true }"
                  :autoplay="{delay: 5000,}" :scrollbar="false"
                  :space-between="0" :loop="true">
            <swiper-slide v-for="(item, index) in listData" :key="index">

              <BannerNew :banner="item" :round="round"/>

            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import BannerNew from "@/components/layout/BannerNew.vue";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default {
  components: {BannerNew, Swiper, SwiperSlide},
  props: {
    listData: {
      type: Array,
      default: () => []
    },
    sectionClass: {
      type: String,
      default: ''
    },
    round: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingBanner: true
    };
  },
  computed: {
    ...mapState({
      loading: state => state.app.loading
    })
  },
  mounted() {
    setTimeout(() => {
      this.loadingBanner = false;
    }, 1000);
  }
};
</script>

<style lang="scss">

@import "../../assets/style/style";
@import "https://unpkg.com/swiper@7/swiper-bundle.min.css";

.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: 50%;
  width: var(--swiper-navigation-size);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  background-color: #fff;
  padding: 10px;
  border-radius: 50%;
}


.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  left: 30px;
  right: auto;
}

.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
  content: "prev";
  font-size: 20px !important;
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  right: 30px;
  left: auto;
}

.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
  content: "next";
  font-size: 20px !important;
}
</style>
