<template>
  <div class="container">
    <div class="banner-item" :style="bannerClass">

      <div class="banner-content">
        <h2 class="banner-title" v-html="banner.title"></h2>
        <div class="banner-description" v-html="banner.desc"></div>
        <router-link class="btn btn-lg btn-info" :to="banner.link" v-if="banner.shop || banner.cart">{{
            banner.textShop ? banner.textShop : 'ətraflı'
          }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    banner: {
      type: Object,
      default: () => {
      }
    },
    reverse: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bannerClass() {
      return {
        'background': `url(${this.banner.img}) no-repeat center center`
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.banner-item {
  width: 100%;
  background-size: cover !important;
  border-radius: 10px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;

  .banner-content {
    text-align: center;
    color: #fff;
    padding: 40px  20px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    width: 50%;

    .banner-title {
      font-size: 30px;
      color: #ffffff;
      font-weight: bold;
    }

    :deep(.banner-description ) {
      font-size: 20px;
      color: #ffffff;
      margin-bottom: 20px;

      span {
        margin-right: 10px;
        font-size: 20px;
      }

      del, s {
        color: #bbbbbb;
        text-decoration: line-through;
        font-size: 15px !important;
      }

    }

  }

}
</style>
